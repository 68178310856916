<template>
  <v-card elevation="1" class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <v-btn
          icon
          class="menubar__button"
          @click="onImageButtonClick()"
        >
          <v-icon> image </v-icon>
        </v-btn>
        <input
          ref="uploader"
          type="file"
          accept="image/*"
          style="display: none"
          @change="(e) => onFileChanged(commands.image, e)"
        >
        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <v-icon> format_bold </v-icon>
        </v-btn>

        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <v-icon> format_italic </v-icon>
        </v-btn>

        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <v-icon> strikethrough_s </v-icon>
        </v-btn>

        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <v-icon> format_underlined </v-icon>
        </v-btn>

        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          P
        </v-btn>

        <!-- <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </v-btn>

        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </v-btn>

        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </v-btn> -->

        <v-btn
          icon
          :class="{ 'is-active': isActive.paragraph({ textAlign: 'left' }) }"
          @click="commands.paragraph({ textAlign: 'left' })">
          <v-icon> format_align_left </v-icon>
        </v-btn>

        <v-btn
          icon
          :class="{ 'is-active': isActive.paragraph({ textAlign: 'center' }) }"
          @click="commands.paragraph({ textAlign: 'center' })">
          <v-icon> format_align_center </v-icon>
        </v-btn>

        <v-btn
          icon
          :class="{ 'is-active': isActive.paragraph({ textAlign: 'right' }) }"
          @click="commands.paragraph({ textAlign: 'right' })">
          <v-icon> format_align_right </v-icon>
        </v-btn>

        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <v-icon> format_list_bulleted </v-icon>
        </v-btn>

        <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <v-icon> format_list_numbered </v-icon>
        </v-btn>

         <!-- <v-btn
          icon
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <v-icon> format_quote </v-icon>
        </v-btn> -->

        <!-- <v-btn
          icon
          class="menubar__button"
          @click="commands.horizontal_rule"
        >
          <v-icon> horizontal_rule </v-icon>
        </v-btn> -->

        <v-btn
          icon
          class="menubar__button"
          @click="commands.undo"
        >
          <v-icon> undo </v-icon>
        </v-btn>

        <!-- <v-btn
          icon
          class="menubar__button"
          @click="commands.redo"
        >
          <v-icon> redo </v-icon>
        </v-btn> -->

      </div>
    </editor-menu-bar>
    <editor-content class="editor__content" :editor="editor" v-model="newContent"/>
  </v-card>
</template>

<script>
import { Editor, EditorMenuBar } from 'tiptap'
import EditorContent from '@/components/EditorContent'
import Paragraph from '@/plugins/paragraph.js'
import resizeImage from '@/plugins/imageResize.js'
import {
  Image,
  Blockquote,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Focus
} from 'tiptap-extensions'
import AliOssService from '../services/AliOssService'
const config = require('../config/config')
export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  model: { // this model can change the default input event into something else e.g., change in this case.
    prop: 'content',
    event: 'change'
  },
  props: {
    content: String
  },
  computed: {
    newContent: { // newContent to get rid of muting props warning which is not clear to me at the moment.
      get: function () {
        return this.content
      },
      set: function (value) {
        this.$emit('change', value)
      }
    }
  },
  data () {
    return {
      editor: new Editor({
        extensions: [
          new Image(),
          new Paragraph(),
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Focus({
            className: 'has-focus',
            nested: true
          })
        ],
        editorProps: {
          handlePaste: (view, event, slice) => {
            // console.log(`Some new content was added from the user's clipboard. slice.content: ${slice.content}`)
            // 传入的数据为JSON结构，以下代码可以对编辑器内容重置。但仍不太明白如何改变粘贴的内容。
            // let testContent = { type: 'doc', content: slice.content }
            // {
            //   'type': 'doc',
            //   'content': [
            //     {
            //       'type': 'heading',
            //       'attrs': { 'level': 2 },
            //       'content': [{ 'type': 'text', 'marks': [{ 'type': 'bold' }], 'text': 'Comments' }]
            //     },
            //     {
            //       'type': 'paragraph',
            //       'content': [
            //         {
            //           'type': 'image',
            //           'attrs': { 'src': 'https://avatars.githubusercontent.com/u/45076741?s=88&u=089050a5cdf326e7e6526471926f62720191b140&v=4', 'alt': '@bdrtsky', 'title': null },
            //           'marks': [
            //             {
            //               'type': 'link',
            //               'attrs': { 'href': 'https://github.com/bdrtsky' }
            //             }
            //           ]
            //         }
            //       ]
            //     }
            //   ]
            // }
            // let options = {}
            // this.editor.setContent(testContent, false, options)
          }
        }
      })
    }
  },
  methods: {
    onImageButtonClick () {
      this.$refs.uploader.click()
    },
    async onFileChanged (command, e) {
      let file = e.target.files[0]
      console.log(`file selected: filename: ${file.name}`)
      // compress the image if necessary
      const resizedImage = await resizeImage({ file: file, maxSize: config.imageMaxSizePx })
      // const imgFileUrl = URL.createObjectURL(resizedImage)
      const imgFile = new File([resizedImage], 'programContentImage_' + file.name) // convert blob to file for uploading
      let { imgUrl, fileName } = await AliOssService.uploadToAliOss({ file: imgFile, publicRead: true }) // 上传至公共读区域
      command({ src: imgUrl, alt: fileName })
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menubar {
  padding: 1em;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: WhiteSmoke;
  background-color: WhiteSmoke;
}
.is-active {
  background-color:lightgrey;
}
.editor {
  border-style: solid;
  border-width: 1px;
  padding: 0em;
}
.editor__content {
  padding: 1em;
  height: 100%;
}
/* use >>> as deep selector to control img under this .editor__content class */
.editor__content >>> img {
  max-width: 100%;
  height: auto;
  background-color: transparent;
}
</style>

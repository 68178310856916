var render, staticRenderFns
import script from "./EditorContent.vue?vue&type=script&lang=js&"
export * from "./EditorContent.vue?vue&type=script&lang=js&"
import style0 from "./EditorContent.vue?vue&type=style&index=0&id=19356523&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19356523",
  null
  
)

export default component.exports